<template>
  <pro-card>
    <div style="text-align: center; opacity: 0.3">
      <img src="../assets/img/logo_cor.svg" alt="" style="width: 50%">
    </div>
  </pro-card>
</template>

<script>
/* eslint-disable */
import miscMixin from '../helpers/misc'
  export default {
    mixins: [miscMixin],
    mounted() {
    }
  }
</script>
