<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <p-form showSearch>
      <template v-slot:content>
        <v-container fluid>
          <table-iterator :itens="listaUsuarios.data" @item=itens @ordem="ordem" @paginas="pegarNovaLista"
                          :setPagina="setPage" :totalPaginas="listaUsuarios.last_page" @por_paginas="setPorPaginas"
                          :PorPage="parseInt(listaUsuarios.per_page)" :cabecalho="cabecalho" :show-add-button="true"
                          header @novo="novo">
            <template v-slot:[n.slot_name]="props" v-for="(n, index) in cabecalho">
              <div :key="'outros'+index">{{props.item[n.slot_name]}}</div>
            </template>
            <template v-slot:acao="props">
              <div style="width: 100%; text-align: right;">
                <v-btn variant="text" icon size="small">
                  <v-icon color="green" @click="editar(props.item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn variant="text" icon size="small">
                  <v-icon color="red" @click="remover(props.item)">mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </table-iterator>

        </v-container>
        <pro-modal-cadastro :modal="dialogCadastro" width="50%" titulo="Cadastrar Usuário">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="usuario.nome" label="Nome Completo" hide-details outlined dense
                              id="nome" :color="setRequerido(usuario.nome)"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="usuario.email" label="Email" hide-details outlined id="email" dense
                              :color="setRequerido(usuario.email)"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="usuario.senha" label="Senha" id="senha" dense
                              :type="show1 ? 'text' : 'password'" hide-details
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="show1 = !show1" outlined
                              :color="setRequerido(usuario.senha)"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="commit()">
              Confirmar
            </v-btn>
            <v-btn text @click="cancelar()">
              Cancelar
            </v-btn>
          </v-card-actions>
        </pro-modal-cadastro>
      </template>
    </p-form>
  </div>
</template>

<script>
/* eslint-disable */
  import {mapActions, mapGetters} from "vuex";
  import paginacao from '../../helpers/paginacao'
  import miscMixin from '../../helpers/misc'
  import Usuario from "../../models/usuario";
  import {acaoApi} from "../../helpers/enums";
import {EventBus} from '../../helpers/event-bus'
  export default {
    mixins: [miscMixin, paginacao],
    name: "Usuarios",
    data: () => ({
      usuario: [],
      editando: false,
      removendo: false,
      dialogCadastro: false,
      show1: false,
      show2: false,
      loading: false,
      cabecalho: [
        {text: 'Nome', value: 'nome', align: 'left', slot: 'item.nome', slot_name: 'nome'},
        {text: 'Email', value: 'email', align: 'left', slot: 'item.email', slot_name: 'email'},
        {text: 'Opções', value: 'actions', align: 'right', notBtn: true, sortable: false}
      ],
    }),
    methods: {
      ...mapActions('usuario', ['setAllUsuarios', 'setFiltro', 'searchItem', 'processarUsuario']),
      ordem(itemsOrder) {
        this.filtro.orders = []
        this.filtro.addOrder(itemsOrder.campo, itemsOrder.direction)
        this.carregarLista()
      },
      setPorPaginas(itemsPerPage) {
        this.filtro.perPage = this.itemsPerPage = itemsPerPage
      },
      pegarNovaLista(page) {
        this.setPage = false
        this.filtro.page = page
        this.filtro.perPage = this.itemsPerPage

        this.carregarLista()
      },
      cancelar() {
        this.dialogCadastro = !this.dialogCadastro
        this.carregarLista()
      },
      commit() {
        this.loading = true
        this.processarUsuario({
          usuario: this.usuario,
          acao: acaoApi.GRAVAR,
          onSucesso: () => {
            this.loading = false
            this.usuario = new Usuario()
            this.cancelar()
          },
          onErro: () => {
            this.loading = false
          }
        })

      },
      editar(item) {
        // console.log('editar: ',item);
        this.usuario = item
        this.editando = true

        this.cancelar()
      },
      remover(item) {
        this.removendo = !this.removendo
        let _this = this
        this.$dialog.confirm('Deseja excluir esse Registro?')
          .then(function (dialog) {
            _this.processarUsuario({
              usuario: item,
              acao:acaoApi.REMOVER,
              onSucesso: () => {
                _this.closeDialog(dialog)
                _this.carregarLista()
              },
              onErro: () => {
              }
            })
          })
      },
      novo() {
        this.editando = false
        this.dialogCadastro = !this.dialogCadastro
        this.usuario = new Usuario()
      },
      carregarLista() {
        this.setAllUsuarios({
          onSucesso: () => {
            EventBus.$emit('SHOW_LISTA', true)
          },
          onErro: () => {
            EventBus.$emit('SHOW_LISTA', false)
          }
        })
      }
    },
    components: {},
    computed: {
      ...mapGetters('usuario', {listaUsuarios: 'listaUsuarios', filtro: 'pegaFiltro'}),
      ...mapGetters('geral', {
        search: 'getSearch',
      })
    },
    created() {
      this.setFiltro(this.itemsPerPage)
      this.carregarLista()
    },
    mounted() {
      // EventBus.$on('CLICK_BTN_ADD', () => {
      //   this.setLog(new LogAudit('.01'))
      //   sessionStorage.setItem('editando', '0')
      //   this.setDisableField(false)
      //   this.setEmpresa(new Empresa())
      //   router.push({name: 'EmpresaCadastrar'}).catch(err => {})
      // })
    },
    watch: {
      itemsPerPage: function () {
        this.carregarLista()
      },
      search: function () {
        this.setFiltro(this.itemsPerPage)
        this.searchItem(this.search)
        this.carregarLista()
      }
    },
  }
</script>
