/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Usuario {
  constructor () {
    this.usuario_id = uuidV4()
    this.name = ''
    this.email = ''
    this.senha = ''
  }
}
