<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <p-form showSearch>
      <template v-slot:content>
        <v-container fluid>
          <table-iterator :itens="listaCartaos.data" @item=itens @ordem="ordem" @paginas="pegarNovaLista"
                          :setPagina="setPage" :totalPaginas="listaCartaos.last_page" @por_paginas="setPorPaginas"
                          :PorPage="parseInt(listaCartaos.per_page)" :cabecalho="cabecalho" :show-add-button="true"
                          :header="false" @novo="novo">
            <template v-slot:[n.slot]="props" v-for="(n, index) in cabecalho">
              <div v-if="n.slot === 'jogo'" :key="'jogo'+index">
                <v-chip  v-for="(item, i) in preencherJogo(props.item)" :key="'chip'+i">{{zeroEsquerda(item,2)}}</v-chip>
              </div>
              <div v-else :key="'outros'+index">{{props.item[n.slot]}}</div>
            </template>
            <template v-slot:acao="props">
              <div style="width: 100%; text-align: right;">
                <v-btn variant="text" icon size="small">
                  <v-icon color="green" @click="editar(props.item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn variant="text" icon size="small">
                  <v-icon color="red" @click="remover(props.item)">mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </table-iterator>

        </v-container>
        <pro-modal-cadastro :modal="dialogCadastro" width="50%" titulo="Cadastrar Cartao">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="cartao.nome" label="Nome" hide-details outlined dense id="nome">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="jogo">
                <table>
                  <tr v-for="(item, i) in cartaoArray" :key="'linha'+i">
                    <td v-for="(col, index) in item.linha" :key="'col' +i+ index" @click="selecionar(col)"
                        :class="getClasse(col)">{{zeroEsquerda(col,2)}}
                    </td>
                  </tr>
                </table>
                <div style="text-align: left">
                  <br>
                  <p>Qtd. Núm. selecionados: {{meuJogo.length}}</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="commit()" :loading="loading">
              Confirmar
            </v-btn>
            <v-btn text @click="cancelar()">
              Cancelar
            </v-btn>
          </v-card-actions>
        </pro-modal-cadastro>
      </template>
    </p-form>
  </div>
</template>

<script>
/* eslint-disable */
  import {mapActions, mapGetters} from 'vuex'
  import paginacao from '../../helpers/paginacao'
  import miscMixin from '../../helpers/misc'
  import Cartao from '../../models/cartao'
  import {acaoApi} from '../../helpers/enums'
  import {EventBus} from '../../helpers/event-bus'

  export default {
    mixins: [miscMixin, paginacao],
    name: 'Cartao',
    data: () => ({
      cartao: [],
      meuJogo: [],
      cartaoArray: [
        {linha: [1, 2, 3, 4, 5]},
        {linha: [6, 7, 8, 9, 10]},
        {linha: [11, 12, 13, 14, 15]},
        {linha: [16, 17, 18, 19, 20]},
        {linha: [21, 22, 23, 24, 25]},
      ],
      editando: false,
      removendo: false,
      dialogCadastro: false,
      show1: false,
      show2: false,
      loading: false,
      cabecalho: [
        {
          text: 'Nome',
          value: 'nome',
          align: 'left',
          slot: 'nome',
        },
        {
          text: 'Jogo',
          value: 'jogo',
          align: 'left',
          slot: 'jogo',
        },
        {
          text: 'Opções',
          value: 'actions',
          align: 'right',
          notBtn: true,
          sortable: false
        }
      ],
    }),
    methods: {
      ...mapActions('cartao', ['setAllCartaos', 'setFiltro', 'searchItem', 'processarCartao']),
      // getClasseAcertos(item) {
      //   var achou = this.apuracaoJogo.find(list=> list === item)
      //   return this.validarCampo(achou) ? 'is_selected' : 'is_not_selected'
      // },
      preencherJogo(item){
        var array = []
        for (var i = 0; i < 15; i++) {
          array.push(item['num' + (i + 1)])
        }
        return array
      },
      getClasse(num) {
        var achou = this.meuJogo.find(list => list === num)
        return this.validarCampo(achou) ? 'is_selected' : 'is_not_selected'
      },
      selecionar(num) {
        if (this.meuJogo.length <= 0) {
          this.meuJogo.push(num)
        } else {
          // if (this.meuJogo.length<15) {
          var achou = this.meuJogo.find(list => list === num)
          if (!this.validarCampo(achou)) {
            if (this.meuJogo.length < 15) {
              this.meuJogo.push(num)
            } else {
              alert('Voce atigiu o limite de numeros para selecionar')
            }
          } else {
            let pos = this.meuJogo.indexOf(achou)
            this.meuJogo.splice(pos, 1)
          }
        }
      },

      ordem(itemsOrder) {
        this.filtro.orders = []
        this.filtro.addOrder(itemsOrder.campo, itemsOrder.direction)
        this.carregarLista()
      },
      setPorPaginas(itemsPerPage) {
        this.filtro.perPage = this.itemsPerPage = itemsPerPage
      },
      pegarNovaLista(page) {
        this.setPage = false
        this.filtro.page = page
        this.filtro.perPage = this.itemsPerPage

        this.carregarLista()
      },
      cancelar() {
        this.dialogCadastro = !this.dialogCadastro
        this.carregarLista()
      },
      commit() {
        var array = this.ordenarLista(this.meuJogo)
        for (var i = 0; i < array.length; i++) {
          this.cartao['num' + (i + 1)] = array[i]
        }
        this.processarCartao({
          cartao: this.cartao,
          acao: acaoApi.GRAVAR,
          onSucesso: () => {
            this.loading = false
            this.cartao = new Cartao()
            this.meuJogo = []
            this.cancelar()
          },
          onErro: () => {
            this.loading = false
          }
        })

      },
      editar(item) {
        this.cartao = item
        this.editando = true
        this.meuJogo = []

        for (var i = 0; i < 15; i++) {
          this.meuJogo.push(this.cartao['num' + (i + 1)])
        }

        this.cancelar()
      },
      remover(item) {
        this.removendo = !this.removendo
        let _this = this
        this.$dialog.confirm('Deseja excluir esse Registro?')
          .then(function(dialog) {
            _this.processarCartao({
              cartao: item,
              acao: acaoApi.REMOVER,
              onSucesso: () => {
                _this.closeDialog(dialog)
                _this.carregarLista()
              },
              onErro: () => {
              }
            })
          })
      },
      novo() {
        this.editando = false
        this.file = null
        this.dialogCadastro = !this.dialogCadastro
        this.cartao = new Cartao()
        this.imagens = []
      },
      carregarLista() {
        this.setAllCartaos({
          onSucesso: () => {
            EventBus.$emit('SHOW_LISTA', true)
          },
          onErro: () => {
            EventBus.$emit('SHOW_LISTA', false)
          }
        })
      }
    },
    computed: {
      ...mapGetters('cartao', {
        listaCartaos: 'listaCartaos',
        filtro: 'pegaFiltro'
      }),
      ...mapGetters('geral', {
        search: 'getSearch',
      })
    },
    created() {
      this.setFiltro(this.itemsPerPage)
      this.carregarLista()
    },
    mounted() {
    },
    watch: {
      itemsPerPage: function() {
        this.carregarLista()
      },
      search: function() {
        this.setFiltro(this.itemsPerPage)
        this.searchItem(this.search)
        this.carregarLista()
      }
    },
  }
</script>
<style scoped lang="scss">
  .jogo {
    tr, td {
      padding: 10px;
      border: 1px solid #ccc;
      text-align: center;
      font-size: 20px;

    }

    table {
      border: 1px solid #ccc;
      padding: 5px;
      text-align: center;
    }
  }

</style>
